import React from "react";
import { Link, graphql } from "gatsby";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";


const AlbumPage = ({ data }) => {
  const {
    number,
    title,
  } = data.albumsYaml;

  return (<>
    <Helmet>
      <meta http-equiv="refresh" content={`0;url=/${kebabCase(`${number}-${title}`)}`} />
    </Helmet>
    <div>Redirecting to{' '}
      <Link to={"/" + kebabCase(`${number}-${title}`)}>
        {`${number}-${title}`}
      </Link>
    </div>
  </>)
};

export default AlbumPage;

export const pageQuery = graphql`
  query AlbumById(
    $id: String!
  ) {
    albumsYaml(
  		id: { eq: $id }
    ) {
      number
      title
    }
  }
`;
